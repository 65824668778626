const hasSymbol = typeof Symbol === 'function' && Symbol.for

export const PLUS_SECURE_TYPE = hasSymbol ? Symbol.for('plus.secure') : 0xea60

export const PLUS_NORMAL_TYPE = hasSymbol ? Symbol.for('plus.normal') : 0xea61

export const PLUS_PURE_TYPE = hasSymbol ? Symbol.for('plus.pure') : 0xea62

export const PLUS_LIMIT_INVITE_TYPE = hasSymbol ? Symbol.for('plus.limit_invite') : 0xea63

export const PLUS_LIMIT_BILLING_TYPE = hasSymbol ? Symbol.for('plus.limit_billing') : 0xea64

export const PLUS_HIDE_HAF_TYPE = hasSymbol ? Symbol.for('plus.hide_haf') : 0xea65
