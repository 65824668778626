import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { palette } from '@zcool/theme'
import Flex from '@zcool/flex'
import Icon from 'components/ui/icon'
import { getFooter } from 'api/footer'

interface Props {
  mode?: PLUS.Mode
}

const StyledFooter = styled.footer<Props>`
  background: ${(props) => props.theme.background};
  color: ${(props) => props.theme.color};
  padding-top: 56px;
  font-size: 14px;
  min-width: 1160px;
  height: 360px;
  border-top: 1px solid ${(props) => props.theme.borderColor};

  main.false.old + & {
    margin-left: 140px;
  }

  main.true.old + & {
    margin-left: 240px;
  }

  main.true.new + & {
    margin-left: 200px;
  }

  main.false.new + & {
    margin-left: 0;
  }

  .footer-media,
  .footer-address {
    margin: 0 auto;
    width: 1180px;
  }

  .footer-address {
    color: ${(props) => props.theme.addressColor};

    > h3 {
      display: none;
    }

    > div {
      width: 1146px;
      display: flex;
      justify-content: space-between;

      .item {
        line-height: 2;
        text-align: left;
        display: inline-block;
      }
    }
  }

  .footer-media {
    display: flex;
    justify-content: space-between;
    color: ${(props) => props.theme.mediaColor};
    margin-top: 60px;
    line-height: 16px;
    font-size: 12px;

    > img {
      margin-right: 24px;
      height: 44px;
    }

    > div {
      flex: 1;
      max-width: 1000px;
    }

    .copyright {
      padding-bottom: 8px;
      border-bottom: 1px solid ${(props) => props.theme.copyrightBottom};

      .tel {
        color: ${(props) => props.theme.telColor};
        font-size: 16px;
        display: flex;
        align-items: center;

        svg {
          margin-right: 8px;
        }
      }
    }

    .icp {
      padding-top: 8px;

      .icps {
        flex-direction: column;
        display: flex;

        a:not(:last-child) {
          margin-bottom: 4px;
        }
      }
    }

    .media-link {
      &.ml16 {
        margin-left: 16px;
      }

      :hover {
        color: ${(props) => props.theme.addressColor};
      }
    }
  }

  @media (max-width: 1280px) {
    .footer-address > div .item {
      margin: 0 4px;
    }

    padding: 40px 8px;
  }
`

const modeMap = {
  dark: {
    background: '#282828',
    borderColor: 'transparent',
    copyrightBottom: palette.black3,
    addressColor: palette.pewter,
    color: palette.pewter,
    mediaColor: palette.spruce,
    telColor: palette.white,
  },
  light: {
    background: palette.pearl,
    borderColor: 'rgba(204, 204, 204, 0.5)',
    copyrightBottom: palette.gray,
    addressColor: palette.spruce,
    color: '#2a2a2a',
    mediaColor: palette.pewter,
    telColor: '#000',
  },
}

export default function Footer({ mode = 'dark' }: Props) {
  const [footer, setFooter] = useState(null)

  useEffect(() => {
    getFooter().then((res) => setFooter(res.data))
  }, [])

  return (
    <StyledFooter theme={modeMap[mode]}>
      <div className="footer-address" dangerouslySetInnerHTML={{ __html: footer }} />
      <div className="footer-media">
        <img src={`/footer-logo${mode === 'light' ? '-reverse' : ''}.svg`} />
        <Flex direction="column" halign="stretch">
          <Flex halign="space-between" className="copyright">
            <span className="tel">
              <Icon glyph="phone" size={16} />
              400-6066-100
            </span>
            <span>
              <span>北京站酷网络科技有限公司旗下产品</span>
              <a
                href="https://www.hellorf.com/help/privacy"
                target="_blank"
                className="media-link ml16"
                rel="noopener noreferrer"
              >
                隐私政策
              </a>
              {` | `}
              <Link href="/events">
                <a className="media-link">活动</a>
              </Link>
            </span>
          </Flex>
          <Flex halign="space-between" valign="center" className="icp">
            <span>{`Copyright © 2014-${new Date().getFullYear()} 站酷海洛 版权所有`}</span>
            <span className="icps">
              <a href="http://beian.miit.gov.cn/" target="_blank" className="media-link ml16" rel="noopener noreferrer">
                京ICP备11017824号-7
              </a>

              <a href="http://beian.miit.gov.cn/" target="_blank" className="media-link ml16" rel="noopener noreferrer">
                京ICP证130164号
              </a>
              <a
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502034683"
                target="_blank"
                className="media-link ml16"
                rel="noopener noreferrer"
              >
                <img src="//static.hellorf.com/hellorf/_next/images/icon-police.png" />
                &nbsp;&nbsp;京公网安备 11010502034683号
              </a>
              <a target="_blank" className="media-link ml16" rel="noopener noreferrer">
                网信算备110105206777001240019号
              </a>
            </span>
            {/*  */}
          </Flex>
        </Flex>
      </div>
    </StyledFooter>
  )
}
