import React from 'react'
import { FormattedMessage } from 'react-intl'
import Link from 'next/link'
import Flex from '@zcool/flex'
import { spacing } from '@zcool/theme'
import { Text } from 'components/ui/base'
import Drawer from 'components/drawer'

export default function Error({
  statusCode,
  message,
  href = '/creative',
}: {
  statusCode: number
  message?: string
  href?: string
}) {
  const errorMap = [
    {
      status: 404,
      message: <FormattedMessage id="errors.404" />,
      imageWidth: 255,
    },
    {
      status: 410,
      message: <FormattedMessage id="errors.410" />,
      imageWidth: 300,
    },
    {
      status: 500,
      message: <FormattedMessage id="errors.500" />,
      imageWidth: 238,
    },
    {
      status: 408,
      message: (
        <>
          <FormattedMessage id="errors.408" />
          &nbsp;&nbsp;
          {/* eslint-disable-next-line no-restricted-globals */}
          <span className="link__underline" onClick={() => location.reload()}>
            <FormattedMessage id="actions.retry" />
          </span>
        </>
      ),
    },
  ]

  if (message === 'ECONNABORTED') {
    // eslint-disable-next-line no-param-reassign
    statusCode = 408
    // eslint-disable-next-line no-param-reassign
    message = ''
  }

  const error = errorMap.find((e) => e.status === statusCode) || errorMap[2]

  const errContent = (
    <Flex direction="column" valign="center" halign="center" as="main" style={{ height: 'calc(100vh - 90px)' }}>
      <img src={`/${error.status === 408 ? 500 : error.status}.svg`} width={error.imageWidth} />
      <br />
      <Text mb={spacing.lg} color="stone">
        {message || error.message}
      </Text>
      <Link href={href}>
        <a className="link__underline">
          <FormattedMessage id="actions.back-to-home" />
        </a>
      </Link>
    </Flex>
  )

  if (statusCode === 410 || statusCode === 500) {
    return <Drawer>{errContent}</Drawer>
  }

  return errContent
}

Error.getInitialProps = function ({ res, err }: any) {
  const statusCode = res ? res.statusCode : err ? err.statusCode : null
  return { statusCode }
}
