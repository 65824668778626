import { events } from 'helpers/event-emitter'
import { ACTIONS } from './constants'

export function showModal(type: string, props?: any) {
  events.emit(ACTIONS.SHOW_MODAL, { type, props })
}

export function hideModal(type: string) {
  events.emit(ACTIONS.HIDE_MODAL, type)
}

export function toast(message: React.ReactNode, timeout = 2000) {
  events.emit(ACTIONS.ADD_TOAST, { message, timeout })
}

// 批量收藏
export function batchCollect(checkedIds: Array<string>) {
  events.emit(ACTIONS.BATCH_COLLECTION, checkedIds)
}

// 全选
export function checkAllImages(checked: boolean) {
  events.emit(ACTIONS.CHECK_IMAGE_ALL_ITEMS, {
    isChecked: checked,
    fromAll: true,
  })
}

// 更新购物车数量
export function updateCartCounts(count: number) {
  events.emit(ACTIONS.UPDATE_CART_COUNT, count)
}
