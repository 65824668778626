import { MeituAnalyze } from 'typings/meitu-analyze'
import { canUseDOM, handlePollingMtstatIsInitialized } from './util'
import { MeituAnalyzeSearchContentType, MeituAnalyzeSearchType } from './enums'
import Cookies from 'js-cookie'
import { COOKIE_DOMAIN } from 'shared/env'

// 本地调试的时候改成false
// const bool = false
const bool = process.env.NODE_ENV === 'development'

/** meitu埋点初始化 */
export const meituAnalyzeInit = (currentUser) => {
  // 环境判断 非开发
  if (bool) return
  // windows 浏览器环境
  if (!canUseDOM()) return

  /** sdk初始化 */
  const initFunction = () => {
    // console.warn('device_id ===========> ', Cookies.get('device_id'))
    // console.warn('mt_device_id ===========> ', window.mtstat.getDeviceId())
    // 监听主站发送事件
    // window.addEventListener('message', function (e) {
    //   if (e.origin + '/syncDeviceId' !== iframeSrc) return
    //   const deviceId = e.data.DeviceId
    const device_id = Cookies.get('device_id')
    !Cookies.get('mt_device_id') && Cookies.set('mt_device_id', window.mtstat.getDeviceId(), { domain: COOKIE_DOMAIN })
    const { locale } = window.__NEXT_DATA__.props
    window.mtstat.init({
      module_id: 'hellorf_plus',
      app_key: process.env.ENV === 'production' ? 'DCAB804E1A9C465A' : 'D837460613449DEB',
      env: process.env.ENV === 'production' ? 'release' : 'test',
      app_language: locale === 'en' ? 'en-CN' : 'zh-CN',
      customizedStatsMarker: device_id,
    })
    // 公共参数
    const { passport_id, id } = currentUser || {}
    const commonParam: MeituAnalyze.CommonParam = {
      zcool_uid: String(passport_id),
      gid: String(id),
      device_id,
    }
    window.mtstat.setCommonParams(commonParam)

    if (currentUser) {
      // 美图登录
      window.mtstat.login(passport_id || id)
    } else {
      // 美图登出
      window.mtstat.logout()
    }
    // })
  }

  if (!window.mtstat) {
    // 没有mtstat则动态加载sdk
    const script = document.createElement('script')
    script.src = '//public.static.meitudata.com/meitu/mtstat-sdk/mtstat-sdk.min.js'
    script.onload = function () {
      // if (window.location.href.includes('syncDeviceId')) return
      initFunction()
    }
    // 处理加载错误的回调函数
    script.onerror = function () {
      console.error('Failed to load the script.')
    }
    document.head.appendChild(script)
  } else {
    // 有mtstat直接执行
    initFunction()
  }
}

/** 封装美图sdk埋点方法 */
const meituTrack = (track, props) => {
  // 环境判断 非开发
  if (bool) return
  // windows 浏览器环境
  if (!canUseDOM()) return
  // 美图sdk 加载成功
  if (!window.mtstat) return
  // 是否init sdk初始化成功
  handlePollingMtstatIsInitialized(() => window.mtstat.track(track, props))
}

/** 搜索点击 */
export const meituSearchClick = (props: MeituAnalyze.SearchClickParam) => {
  meituTrack('plus_search_click', props)
}

/** 搜索成功 */
export const meituSearchSuccess = (props: MeituAnalyze.SearchSuccessParam) => {
  meituTrack('plus_search_success', props)
}

/** 搜索失败 */
export const meituSearchFail = (props: MeituAnalyze.SearchFailParam) => {
  meituTrack('plus_search_fail', props)
}

/** 图片搜索结果页类型点击 */
export const meituImageTabClick = (props: MeituAnalyze.ImageTabClickParam) => {
  meituTrack('plus_image_tab_click', props)
}

/** 视频搜索结果页类型点击 */

export const meituVideoTabClick = (props: MeituAnalyze.VideoParams) => {
  meituTrack('plus_video_tab_click', props)
}

/** 字体搜索结果页类型点击 */

export const meituTypeFaceTabClick = (props: MeituAnalyze.FontParams) => {
  meituTrack('plus_typeface_tab_click', props)
}

/** 音乐搜索结果页类型点击 */
export const meituMusicTabClick = (props: MeituAnalyze.MusicParams) => {
  meituTrack('plus_music_tab_click', props)
}

/** 搜索结果页素材卡片曝光 */
export const meituSearchResultsCardShow = (props: MeituAnalyze.SearchResultsCardClickParams) => {
  meituTrack('plus_search_results_card_show', props)
}

/** 搜索结果页素材卡片点击 */
export const meituSearchResultsCardClick = (props: MeituAnalyze.SearchResultsCardClickParams) => {
  meituTrack('plus_search_results_card_click', props)
}

/** 搜索结果页精选素材曝光 */
export const meituSearchResultsCardSetsShow = (props: MeituAnalyze.SearchResultSetsParams) => {
  meituTrack('plus_search_results_card_sets_show', props)
}

/** 搜索结果页精选素材点击 */
export const meituSearchResultsCardSetsClick = (props: MeituAnalyze.SearchResultSetsParams) => {
  meituTrack('plus_search_results_card_sets_click', props)
}

/**  素材详情页点击 */
export const meituSearchResultsInfoDownloadClick = (props: MeituAnalyze.PlusSearchResultsInfoDownloadParams) => {
  meituTrack('plus_search_results_info_download_click', props)
}

/** 素材收藏点击 */
export const meituCollectClick = (props: MeituAnalyze.CollectClickParams) => {
  meituTrack('plus_collect_click', props)
}

/** 素材收藏弹窗确认点击 */
export const meituCollectConfirmClick = (props: MeituAnalyze.CollectConfirmClickParams) => {
  meituTrack('plus_collect_confirm_click', props)
}

/** 素材成功加入清单 */
export const meituWishListClick = (props: MeituAnalyze.CollectClickParams) => {
  meituTrack('plus_wish_list_click', props)
}

/** 立即购买按钮点击 */
export const meituBuyNowClick = (props: MeituAnalyze.BuyNowClickParams) => {
  meituTrack('plus_buy_now_click', props)
}

/** 购买授权弹窗按钮点击 */
export const meituPlusServicePayClick = (props: MeituAnalyze.PlusServicePayClickParams) => {
  return props.key_word === '1' // 无用代码
  // meituTrack('plus_service_pay_click', props)
}

// /** ip合作咨询弹窗按钮点击 */
// export const meituIpTeamworkConsultClick = (props: MeituAnalyze.IpTeamworkConsultClickParams) => {
//   meituTrack('plus_ip_teamwork_consult_click', props)
// }

/** 素材详情页曝光 */
export const meituSearchResultsInfoShow = (props: MeituAnalyze.SearchResultsInfoShowParams) => {
  meituTrack('plus_search_results_info_show', props)
}

/** 聚合请求成功失败埋点函数 */
export const meituSearchApiStatistics = ({
  result,
  keyword,
  searchType,
  searchContentType,
  searchid,
  total,
  message,
}) => {
  try {
    const search_type = MeituAnalyzeSearchType[searchType as keyof typeof MeituAnalyzeSearchType]
    const search_content_type =
      MeituAnalyzeSearchContentType[searchContentType as keyof typeof MeituAnalyzeSearchContentType]

    if (result) {
      meituSearchSuccess({
        key_word: keyword,
        request_id: searchid,
        search_result_num: total,
        search_type,
        search_content_type,
      })
    } else {
      meituSearchFail({
        key_word: keyword,
        request_id: searchid,
        reason: message || 'Error NetWork',
        search_type,
        search_content_type,
      })
    }
  } catch (error) {
    console.error(error)
  }
}
