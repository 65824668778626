import React, { useEffect, useRef } from 'react'

interface ComExposeProps {
  children: any
  className?: string
  // 是否一直有效
  readonly always?: boolean
  // 曝光时的回调，若不存在always，则只执行一次
  onExpose?: () => void
  // 曝光后又隐藏的回调，若不存在always，则只执行一次
  onHide?: (dom: HTMLElement) => void
  // IntersectionObserver相关的配置
  observerOptions?: IntersectionObserverInit
}

const ComExpose = (props: ComExposeProps): JSX.Element => {
  const ref = useRef(null)
  const curExpose = useRef(null)
  useEffect(() => {
    if (ref.current) {
      const target = ref.current
      const observerOptions = props?.observerOptions || {
        // 触发回调的节点
        // 0 表示元素刚不可见
        // 0.5 表示元素可见一半
        // 1 表示元素刚完全可见
        threshold: 0.0,
      }
      const intersectionCallback = (entries: IntersectionObserverEntry[]) => {
        const [entry] = entries
        if (entry.isIntersecting) {
          // intersectionRatio 比例
          if (entry.intersectionRatio >= Number(observerOptions.threshold)) {
            if (!curExpose.current) {
              // 上报数据
              props?.onExpose()
            }
            curExpose.current = true
            if (!props?.always && typeof props?.onHide !== 'function') {
              // 当always属性为false，且没有onHide方式时
              // 则在执行一次曝光后，移除监听
              io.unobserve(target)
            }
          }
        } else if (typeof props?.onHide === 'function' && curExpose.current) {
          props.onHide(target)
          curExpose.current = false
          if (!props?.always) {
            io.unobserve(target)
          }
        }
      }

      const io = new IntersectionObserver(intersectionCallback, observerOptions)

      setTimeout(() => {
        io.observe(target)
      }, 300)

      // 组件被卸载时，先取消监听
      return () => io.unobserve(target)
    }
  }, [ref])

  // 为该组件挂在ref属性
  // return React.cloneElement(props.children, { ref });
  return (
    <div ref={ref!} className={props.className}>
      {props.children}
    </div>
  )
}

export default ComExpose
