/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
import throttle from 'lodash.throttle'
import API from 'api'
import { FontPackageType, Platform } from 'helpers/enums'
import invariant from 'invariant'
import cookie from 'js-cookie'
import getConfig from 'next/config'
import { DAM_CONTENTS, LEGAL_DOMAIN, PLUS_CONTENTS, SHOW_SHEIN_MODAL, STORAGE_KEYS } from 'shared/constants'
import parse from 'url-parse'
import { getUserAccountInfo } from 'api/user'
import { showModal } from 'shared/actions'
import SparkMD5 from 'spark-md5'

const now = +new Date()
let index = 0

export function getUid() {
  return `plus-${now}-${(index += 1)}`
}

export function toLocaleString(n: number, toFixed?: number) {
  let targetNum = n
  if (typeof n !== 'number') {
    targetNum = 0
  }

  let v = targetNum.toFixed(toFixed)
  const reg = /\d{1,3}(?=((\d{3})+(\.\d+)?)$)/g
  v = v.replace(reg, `$&,`)
  return v
}

export function toCurrencyFormat(num: number) {
  let targetNum = num
  if (typeof num !== 'number') {
    targetNum = 0
  }

  let value

  try {
    value = targetNum.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  } catch (error) {
    // fix: safari toLocaleString报错
    value = targetNum.toString()
  }

  return `¥${value}`
}

export function union(a: Array<any>, b: Array<any>): Array<any> {
  return Array.from(new Set([...a, ...b]))
}

export function delay(duration = 2000) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(null)
    }, duration)
  })
}

export function noop() {
  return null
}

export function canUseDOM() {
  return !!(typeof window !== 'undefined' && window.document && window.document.createElement)
}

export function getDocumentScrollTop(): number {
  if (!canUseDOM()) {
    return 0
  }

  const scrollTop = document.documentElement?.scrollTop || window.pageYOffset || document.body?.scrollTop
  return scrollTop
}

/**
 * 根据请求对象生成自定义请求头
 * @param req
 */
export const generateHeaders = (req) => {
  if (!req || !req.session) {
    return null
  }

  const headers: PLUS.Headers = {}

  if (req.headers) {
    if (req.headers.cookie) {
      headers.cookie = req.headers.cookie
    }
  }

  if (req.clientIp && !isDev()) {
    headers['X-Forwarded-For'] = req.clientIp
  }

  return headers
}

export function getCookieByName(req: any, name: string) {
  if (req && req.cookies) {
    return req.cookies[name]
  }

  if (canUseDOM()) {
    return cookie.get(name)
  }

  return null
}

export function loadImage(src: string) {
  const promise = new Promise((resolve, reject) => {
    const img = new Image()
    img.crossOrigin = 'anonymous'

    img.onload = function () {
      resolve(img)
    }

    img.onerror = function () {
      reject(new Error('Image download error'))
    }

    img.onabort = function () {
      reject(new Error('Image download error'))
    }

    img.src = src
  })

  return promise
}

/**
 * 字体预览样式
 * @param req
 */
export function loadFontPreCss(url: string) {
  const promise = new Promise((resolve, reject) => {
    const head = document.getElementsByTagName('head')[0]
    const linkTag = document.createElement('link')

    linkTag.setAttribute('rel', 'stylesheet')
    linkTag.setAttribute('type', 'text/css')

    linkTag.onload = function () {
      resolve(linkTag)
    }
    linkTag.onerror = function () {
      reject(new Error('Get font  css error'))
    }
    linkTag.href = url

    head.appendChild(linkTag)
  })

  return promise
}
/**
 * 裁剪图片
 * @param image
 * @param pixelCrop ReactCrop.PixelCrop
 */
export function getCroppedImg(image: HTMLImageElement, pixelCrop: ReactCrop.PixelCrop) {
  const canvas = document.createElement('canvas')
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height
  const ctx = canvas.getContext('2d')
  let fileUrl

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  )

  return new Promise((resolve) => {
    try {
      // hack ie11
      if (!canvas.toBlob) {
        Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
          value(callback, type, quality) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const self = this
            setTimeout(function () {
              const binStr = atob(self.toDataURL(type, quality).split(',')[1])
              const len = binStr.length
              const arr = new Uint8Array(len)
              for (let i = 0; i < len; i++) {
                arr[i] = binStr.charCodeAt(i)
              }

              callback(new Blob([arr], { type: type || 'image/png' }))
            })
          },
        })
      }
    } finally {
      canvas.toBlob((blob) => {
        if (blob) {
          window.URL.revokeObjectURL(fileUrl)
          fileUrl = window.URL.createObjectURL(blob)
          resolve(fileUrl)
        } else {
          resolve(null)
        }
      }, 'image/jpeg')
    }
  })
}

// 过滤对象空值
export function removeEmpty(obj: { [key: string]: any }) {
  Object.keys(obj).forEach((key) => {
    if (
      obj[key] === undefined ||
      obj[key] === null ||
      obj[key] === '' ||
      (obj[key] && obj[key].toString().trim() === '') ||
      (Array.isArray(obj[key]) && obj[key].length === 0)
    ) {
      // eslint-disable-next-line no-param-reassign
      delete obj[key]
    }
  })

  return obj
}

// 过滤特定值项
export function removeKeyOfValue(obj: { [key: string]: any }, value = -1) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === value) {
      // eslint-disable-next-line no-param-reassign
      delete obj[key]
    }
  })

  return obj
}

// 截取字符串
export function handleString(s: string, length = 10, suffix = '...') {
  let len = 0 // 字节长度
  const a = s.trim().split('')
  for (let i = 0; i < a.length; i++) {
    // 不区分中英文 2019-05-23
    len += 1
    if (len > length) {
      return s.slice(0, i) + suffix
    }
  }
  return s
}

// 按照显示字数的规则截取字符串
export function sliceStr(str: string, len: number) {
  let res = str
  if (res && res.length > len) {
    res = `${res.slice(0, len - 1)}...`
  }
  return res
}

// 判断字符长度 英文、数字、符号均为一个字节,汉字为两个
export function lengthOfString(s: string) {
  const len = s.length
  // 中英文均占一个字符 2020-04-27
  // let a = s.split('')
  // for (let i = 0; i < a.length; i++) {
  //   if (a[i].charCodeAt(0) < 299) {
  //     len = len + 1
  //   } else {
  //     len = len + 2
  //   }
  // }
  return len
}

/**
 * client browser download
 * @param url
 */
export function download(url: string) {
  const a = document.createElement('a')
  a.setAttribute('style', 'display: none;')
  a.href = url
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export const addToCardAnimation = (e: { clientX: number; clientY: number }, callback?: () => void) => {
  const cart: HTMLElement = document.getElementById('cart-container')
  const cartBadge: HTMLElement = document.querySelector('.cart-badge')
  const cartSVG: any = cart.firstElementChild
  const cartRect: ClientRect = cart.getBoundingClientRect()
  // 小球中心移至鼠标点击位置
  const currentX = e.clientX - 18
  const currentY = e.clientY - 18
  // 目标区域为购物车中心
  const targetX = cartRect.left + 10
  const targetY = cartRect.top + 10
  const distanceX = targetX - currentX
  const distanceY = currentY - targetY + 30

  const temporaryBox = document.createElement('div')
  const innerBox = document.createElement('div')
  const time = 0.8

  const temporaryBoxTransitionEndFunc = (event) => {
    event.stopPropagation()
    temporaryBox.removeEventListener('transitionend', temporaryBoxTransitionEndFunc)
    document.body.removeChild(temporaryBox)
    cartSVG.style.transition = 'transform 0.1s'
    cartBadge.style.transition = 'transform 0.1s'

    if (callback) {
      callback()
    }

    setTimeout(() => {
      cartSVG.style.transform = 'translateX(-50%) translateY(-50%) rotate(45deg)'
      cartBadge.style.transform = 'scale(1.6, 1.6)'
    }, 0)

    setTimeout(() => {
      cartSVG.style.transition = 'all 0.2s'
      cartBadge.style.transition = 'all 0.2s'

      setTimeout(() => {
        cartSVG.style.transform = 'translateX(-50%) translateY(-50%) rotate(0deg)'
        cartBadge.style.transform = 'scale(1, 1)'
      }, 0)
    }, 100)
  }

  temporaryBox.classList.add('cart-animation-box')
  temporaryBox.style.top = `${currentY}px`
  temporaryBox.style.left = `${currentX}px`
  temporaryBox.style.transition = `transform ${time}s linear`
  innerBox.innerHTML = '+1'
  innerBox.style.transition = `all ${time}s ease-in`

  temporaryBox?.appendChild(innerBox)
  document.body.appendChild(temporaryBox)

  const timer = setInterval(() => {
    if (document.querySelector('.cart-animation-box')) {
      clearInterval(timer)
      temporaryBox.addEventListener('transitionend', temporaryBoxTransitionEndFunc)
      temporaryBox.style.transform = `translateX(${distanceX}px)`
      innerBox.style.transform = `translateY(-${distanceY}px) scale(0.5, 0.5)`
      innerBox.style.opacity = `0`
    }
  }, 1000 / 60)
}

export const deepClone = (obj: any) => {
  const clone = { ...obj }
  Object.keys(clone).forEach((key) => (clone[key] = typeof obj[key] === 'object' ? deepClone(obj[key]) : obj[key]))
  return Array.isArray(obj) && obj.length
    ? (clone.length = obj.length) && Array.from(clone)
    : Array.isArray(obj)
    ? Array.from(obj)
    : clone
}

// 深度取值
export const getIn = (path: Array<string | number>, object, defaultValue?) => {
  const isTrue = (v) => !!v || v === 0
  return path.reduce((xs, x) => (isTrue(xs) && isTrue(xs[x]) ? xs[x] : defaultValue), object)
}

// 获取当前频道
export function getActiveMenuId(path: string, damIndex: boolean) {
  const menu = [...PLUS_CONTENTS, ...DAM_CONTENTS].find((_) => _.pattern.test(path))

  const basicMenu = damIndex ? DAM_CONTENTS : PLUS_CONTENTS

  return menu ? menu.id : basicMenu[0].id
}

export function replaceComma(str: string) {
  if (typeof str === 'string') {
    return str.replace(/,|，/g, ' ')
  }
  return ''
}

export const add0 = (value) => (value < 10 ? `0${value}` : value)

/**
 * 格式化时间段
 * @param duration 时间
 */
export function formatSecondNumber(duration: number): string {
  let s = Math.floor(duration)
  let m = 0
  let h = 0

  if (s >= 60) {
    m = Math.floor(s / 60)
    s = Math.floor(duration % 60)
  }

  if (m >= 60) {
    h = Math.floor(m / 60)
    m = Math.floor(m % 60)
  }

  return h ? `${add0(h)}:${add0(m)}:${add0(s)}` : `${add0(m)}:${add0(s)}`
}

/**
 * 保留小数点
 * @param value 数值
 * @param places 小数位
 */
export function keptDecimalPlaces(value: number, places: number): number {
  return parseFloat(value.toFixed(places))
}

export const handleViewportResize = throttle((ref, margin, itemWidth) => {
  if (ref.current) {
    const boxWidth = ref.current.offsetWidth
    const num = (boxWidth + margin) / itemWidth
    const int = Math.max(4, Math.floor(num))
    const addWidth = ((num - int) * itemWidth) / int
    const w = Math.floor(itemWidth - margin + addWidth)
    return { width: w, int }
  }
  return {} // fix PLUS-37Z
}, 100)

// 添加购物车成功动画并更新购物车数量
export const addSuccess = (event, callback) => {
  addToCardAnimation(event)
  setTimeout(() => {
    callback()
  }, 1000)
}

// 处理数组为 Array<{id:XXX,name:XXX}>
export function parseData(data: Array<any>, idKey?: string, nameKey?: string, levelKey?: string, statusKey?: string) {
  if (idKey && nameKey) {
    return data.map((_) => ({
      id: _[idKey],
      name: _[nameKey],
      level: _[levelKey],
      status: _[statusKey],
    }))
  }

  if (data.every((_) => typeof _ === 'string')) {
    return data.map((_) => ({ id: _, name: _ }))
  }

  return invariant(false, 'Parameter is wrong')
}

export const fileSizeKbToMb = (bit) => {
  const size = bit / 1024 / 1024
  if (size < 0.1) {
    return size ? `${(size * 1024).toFixed(1)}KB` : null
  }
  return size ? `${size.toFixed(1)}MB` : null
}

export const getFileSize = (bytes, decimals = 2): string => {
  if (!bytes) {
    return null
  }

  const sz = 'BKMGTP'
  const factor = Math.floor((bytes.toString().length - 1) / 3)
  if (factor === 0 || factor === 1) {
    // eslint-disable-next-line no-param-reassign
    decimals = 0
  }

  return (bytes / 1024 ** factor).toFixed(decimals) + sz[factor]
}

export function bytesToSize(bytes: number) {
  if (bytes === 0) {
    return ['0', 'B']
  }
  const k = 1024
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
  let i = Math.floor(Math.log(bytes) / Math.log(k))
  i = i > 4 ? 4 : i

  return [(bytes / k ** i).toFixed(2), sizes[i]]
}

/**
 * 预加载资源文件
 * @param src 资源路径
 * @param fileType 资源类型
 */
export const preloadFile = ({ src, fileType }: { src: string; fileType: string }) => {
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Preloading_content
  const fileTypes = ['video', 'audio', 'style', 'script', 'font', 'image', 'object', 'track', 'worker']
  if (!fileTypes.includes(fileType)) {
    throw new Error('unsupported preload file type')
  }

  if (document.head.querySelector(`link[href="${src}"]`)) {
    return
  }

  const link = document.createElement('link')
  link.setAttribute('rel', 'preload')
  link.setAttribute('as', fileType)
  link.setAttribute('href', src)
  document.head.appendChild(link)
}

/**
 * load css/js file with promise
 * @param  src: 路径 id: 唯一id type: 类型
 */
export function loadFile({ src, id, type }: { src: string; id: string; type: 'css' | 'js' }) {
  return new Promise((resolve, reject) => {
    if (!document.getElementById(id)) {
      let file
      switch (type) {
        case 'js':
          file = document.createElement('script')
          file.async = true
          file.id = id
          file.src = src
          document.body.appendChild(file)

          break
        case 'css':
          file = document.createElement('link')
          file.setAttribute('rel', 'stylesheet')
          file.id = id
          file.href = src
          document.head.appendChild(file)
          break
        default:
          break
      }

      file.addEventListener('load', () => {
        resolve(null)
      })
      file.addEventListener('error', (err) => {
        reject(err)
      })
    } else {
      resolve(null)
    }
  })
}

// 判断一个数组是否包含在另一个数组中
export const isInclude = (a, b) => {
  return b.every((item) => a.includes(item))
}

// 简单数组去重
export function uniqueElements(arr: Array<any>) {
  return [...new Set(arr)]
}

// 复杂数组去重
export function uniqueElementsMultiple(arr: Array<any>) {
  const ids = []
  const temp = []
  arr.forEach((item) => {
    if (ids.indexOf(item.id) === -1) {
      ids.push(item.id)
      temp.push(item)
    }
  })

  return temp
}

// 遍历递归数据返回一维数组
export function handleRecurrenceData(recurrenceArr: Array<any>, intendArr: Array<any>) {
  if (recurrenceArr.length) {
    recurrenceArr.forEach((item) => {
      intendArr.push(item)
      if (item && item.children) {
        handleRecurrenceData(item.children, intendArr)
      }
    })
  }
  return intendArr
}

function addPaddingZero(num: number) {
  if (num < 10) {
    return `0${num}`
  }

  return `${num}`
}

// 日期转换
export function getStringDate({ from, to }: { from: PLUS.Date; to: PLUS.Date }) {
  let startTime
  let endTime

  if (from) {
    startTime = `${from.year}-${addPaddingZero(from.month + 1)}-${addPaddingZero(from.day)} 00:00:00`
  }
  if (to) {
    endTime = `${to.year}-${addPaddingZero(to.month + 1)}-${addPaddingZero(to.day)} 23:59:59`
  }

  return { startTime, endTime }
}

export function addZero(m: number) {
  return m < 10 ? `0${m}` : m
}

export function getPreDate(date?: string) {
  const currentDate = date ? new Date(date) : new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth() + 1
  const preDate = new Date(currentDate.setMonth(currentMonth - 12))
  const endDate = `${currentYear}-${addZero(currentMonth)}`
  const startDate = `${preDate.getFullYear()}-${addZero(preDate.getMonth() + 1)}`

  return { startDate, endDate }
}

export function getFileExt(fileName: string): string {
  // testimage.jpg => jpg
  const tempArr = fileName.split('.')
  return tempArr[tempArr.length - 1]
}

/**
 * 浏览器信息
 * @returns {Browser}
 */
export function browserInfo() {
  const _Browser = {
    versions: () => {
      const ua = navigator.userAgent || ''
      return {
        trident: ua.indexOf('Trident') > -1, // IE内核
        presto: ua.indexOf('Presto') > -1, // opera内核
        webKit: ua.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
        gecko: ua.indexOf('Gecko') > -1 && ua.indexOf('KHTML') === -1, // 火狐内核
        isSafari: ua.indexOf('Safari') > -1 && ua.indexOf('Chrome') < 1, // safari
        iPad: ua.indexOf('iPad') > -1, // 是否iPad
        QQBrowser: ua.indexOf('QQBrowser') > -1, // 是否QQ浏览器
        Sogou: ua.indexOf('SE 2.X') > -1, // 是否搜狗浏览器
      }
    },
  }
  return _Browser
}

// 获取购物车数量
export function getCartCount() {
  return getConfig().publicRuntimeConfig.CART_COUNT
}

// 从 constants.js 中根据 value 获取 label
export function getLabelFromConstants(constant: any, value: number | string) {
  let label = null

  constant.forEach((item) => {
    if (item.value === value) {
      return (label = item.label)
    }
  })

  return label
}

// 判断是否为 midea  域名
export function isMidea(domain: string) {
  return domain.indexOf('midea.plus.hellorf.com') > 0
}

// 计算value在数组中出现的次数
export function countValue(arr: Array<any>, value: any) {
  return arr.reduce((a, v) => (v === value ? a + 1 : a + 0), 0)
}

export function isCustomDomain(domain: string) {
  const validDomains = ['http://plus.hellorf.com', 'https://plus.hellorf.com']

  const validHosts = ['plus.hellorf.com', 'in.zcool.cn', 'hellorf.com']

  return !validDomains.includes(domain) && validHosts.some((h) => domain.includes(h))
}

// 获取两个数的最简比
export function getPercentage(m: number, n: number, decimal = 1) {
  if (!m || !n) {
    return '--'
  }

  // 求最大公约数
  function getMaxDivisor(a: number, b: number) {
    let r = 0
    r = a % b
    // eslint-disable-next-line no-param-reassign
    a = b
    if (r === 0) {
      return a
    }
    return getMaxDivisor(a, b)
  }
  const maxDivisor = getMaxDivisor(m, n)

  return `${Number.isInteger(m / maxDivisor) ? m / maxDivisor : (m / maxDivisor).toFixed(decimal)}:${
    Number.isInteger(n / maxDivisor) ? n / maxDivisor : (n / maxDivisor).toFixed(decimal)
  }`
}

// 截取字符串，中间省略...
export function getSubString(
  /** 原始string */
  str: string,
  /** 超过subNum截取 */
  subNum: number,
  /** 截取后面的长度 */
  lastNum = 7
) {
  if (str && str.length > subNum) {
    const endStr = str.slice(-lastNum)
    const startStr = str.slice(0, subNum - lastNum - 1)
    return `${startStr}...${endStr}`
  }
  return str
}

// 是否为正整数
export function isPositiveInteger(value: string | number) {
  const v = typeof value === 'string' ? parseInt(value, 10) : value
  return Number.isInteger(v) && v >= 0
}

export function isNumber(v: string) {
  return /^\d+$/.test(v)
}

export function parseDate2Str(date: { year: number; month: number; day: number }) {
  return `${date.year}-${date.month + 1}-${date.day}`
}

export function parseString2Date(dateStr: string) {
  if (!dateStr) {
    return null
  }
  const items = dateStr.split('-')
  const value = {
    year: parseInt(items[0], 10),
    month: parseInt(items[1], 10) - 1,
    day: parseInt(items[2], 10),
  }
  return value
}

export function blob2Obj(blob) {
  return new Promise((resolve, reject) => {
    let res

    if (canUseDOM() && blob instanceof window.Blob) {
      const fileReader = new FileReader()

      fileReader.onload = function () {
        try {
          res = JSON.parse(fileReader.result as string)
          if (typeof res === 'object' && res !== null) {
            resolve(res)
          }
          resolve(null)
        } catch (error) {
          reject(error)
        }
      }

      fileReader.onerror = function () {
        reject(fileReader.error)
      }

      fileReader.readAsBinaryString(blob)
    } else {
      resolve(res)
    }
  })
}

export function spliceWebPImageUrl(
  imgUrl: string,
  isWebPSupported: boolean,
  opt?: {
    w?: number
    h?: number
    m?: 'lfit' | 'mfit' | 'fill' | 'pad' | 'fixed'
  }
) {
  if (!imgUrl) {
    return ''
  }

  if (['ali.image.hellorf.com', 'hellorfimg.zcool.cn'].every((_) => imgUrl.indexOf(_) === -1)) {
    return imgUrl
  }

  if (imgUrl.indexOf('x-image-process') > -1) {
    return imgUrl
  }

  const hasParams = imgUrl.indexOf('?') > -1

  const params = []
  const { m, w, h } = opt || {}
  if (m) {
    params.push(`m_${m}`)
  }
  if (w) {
    params.push(`w_${w}`)
  }
  if (h) {
    params.push(`h_${h}`)
  }

  if (params.length === 0 && !isWebPSupported) {
    return imgUrl
  }

  let paramsString = 'x-image-process=image'
  if (params.length > 0) {
    paramsString += `/resize,${params.join(',')}`
  }

  if (isWebPSupported) {
    paramsString += `/format,webp`
  }

  if (hasParams) {
    return `${imgUrl}&${paramsString}`
  }

  return `${imgUrl}?${paramsString}`
}

export const checkIsWebPSupported = (req) => {
  if (!req) {
    return window.localStorage.getItem('webp') === '1'
  }

  // text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9
  const headersAccept = req.headers.accept || ''
  return headersAccept.indexOf('webp') > -1
}

export function getIsSingleFontType(type: number, isSingle: boolean) {
  if (type === FontPackageType.SingleStandard || type === FontPackageType.SingleEnhanced) {
    return true
  }
  if (type === FontPackageType.Standard || type === FontPackageType.Enhanced) {
    return false
  }
  return isSingle
}

// 限制 数字类型小数点后只能输入两位
export function priceFilter(val: string) {
  const reg = /^\d+(?:\.\d{0,2})?/
  if (val === '') return ''

  if (Number.isNaN(Number(val))) {
    return val
  }

  return val.match(reg) && val.match(reg)[0]
}

// 搜索请求保存searchID
export function saveSearchId(result: any) {
  try {
    const searchid = typeof result === 'string' ? result : result?.data?.searchid
    window.sessionStorage.setItem(STORAGE_KEYS.SEARCH_ID_KEY, searchid || '')
  } catch (e) {
    // console.error(e)
  } finally {
    // eslint-disable-next-line no-unsafe-finally
    return result
  }
}

// 搜索统计
export function searchStatic(method: Platform, keyword: string) {
  let searchid: string

  const methodAndKeyword = `${method}${keyword}`
  try {
    const originKeyword = window.sessionStorage.getItem('search_keyword')
    if (methodAndKeyword === originKeyword) {
      searchid = window.sessionStorage.getItem(STORAGE_KEYS.SEARCH_ID_KEY)
    }
    window.sessionStorage.setItem('search_keyword', methodAndKeyword)
  } catch (e) {
    // console.error(e)
  } finally {
    API.get(`/index/search-keyword-static`, {
      params: { keyword, searchid, type: method },
    })
  }
}

export function transformPostmessage(e: any) {
  const { publicRuntimeConfig } = getConfig()
  if (e.origin.includes(publicRuntimeConfig.PASSPORT_URL)) {
    // return JSON.parse(e.data)
    return typeof e.data === 'object' ? e.data : JSON.parse(e.data)
  }
}

export function isSafeUrl(url: string): boolean {
  if (!url || typeof url !== 'string') return false

  const { hostname } = parse(decodeURIComponent(url))

  if (!hostname) return false

  return LEGAL_DOMAIN.some((item) => hostname.indexOf(item) > -1)
}

// 是否展示希音弹窗
/**
 * 当是希音用户并且余额为零，每天展示一次。
 */
export async function isShowSheinModal(currentUser?: PLUS.User) {
  if (!canUseDOM() || !currentUser?.is_shein) return
  const showDate = window.localStorage.getItem(SHOW_SHEIN_MODAL)
  const nowObj = new Date()
  const nowDate = `${nowObj.getFullYear()}-${nowObj.getMonth() + 1}-${nowObj.getDate()}`

  if (showDate !== nowDate) {
    const result = await getUserAccountInfo()
    if (result?.result && result.data.cashes === 0) {
      showModal('SHEIN_TOPUP')
    }
  }
}

/**
 *
 * @returns 生成uid
 */
export function generateFileUID() {
  const unique = performance.now().toString(36) + Math.random().toString(36).substr(2, 9)
  return btoa(unique).replace(/=+$/, '') // 使用base64编码并移除末尾的'='
}

/**
 * 计算文件的MD5
 * @param file 文件
 * @param chunkSize 分片大小
 * @returns Promise
 */
export function getFileMD5(file) {
  const chunkSize = 2097152
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
    const chunks = Math.ceil(file.size / chunkSize)
    let currentChunk = 0
    const spark = new SparkMD5.ArrayBuffer()
    const fileReader = new FileReader()

    fileReader.onload = function (e) {
      spark.append(e.target.result)
      currentChunk++
      if (currentChunk < chunks) {
        loadNext()
      } else {
        const md5 = spark.end()
        return resolve(md5)
      }
    }

    fileReader.onerror = function (e) {
      reject(e)
    }

    function loadNext() {
      const start = currentChunk * chunkSize
      let end = start + chunkSize
      if (end > file.size) {
        end = file.size
      }
      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
    }
    loadNext()
  })
}

const getImageSize = (url): Promise<{ width: number; height: number }> => {
  return new Promise(function (resolve) {
    const img = new Image()
    img.onload = function () {
      const { width } = img
      const { height } = img
      resolve({ width, height })
    }
    img.onerror = function () {
      resolve({ width: null, height: null })
      // reject(new Error('无法加载图片'))
    }
    img.src = url
  })
}

export async function filterInputFile(file): Promise<FileType> {
  const imgSize = await getImageSize(URL.createObjectURL(file))
  // const md5 = await getFileMD5(file)
  // console.log('md5==', md5)
  return new Promise((resolve) => {
    const newFile = {
      width: imgSize?.width,
      height: imgSize?.height,
      originFile: file,
      originUrl: URL.createObjectURL(file),
      name: file.name,
      uid: generateFileUID(),
      status: 'waiting',
      mime_type: file.type || file.name.split('.')?.[1],
      extension: file.name.split('.')?.[1],
      thumbUrl: URL.createObjectURL(file),
      file_size: file.size,
      folderId: null,
      errorMessage: '',
      md5_file: '',
    }

    return resolve(newFile)
  })
}

export function fileToFormDataMeitu(file: any, path) {
  // const path = UploadTypeToPath[file?.type]
  const arr = file?.url?.split(`${path}/`) || []
  const len = arr?.length
  return {
    name: len > 0 ? arr?.[len - 1] : '',
  }
}

export function getUrlData(): any {
  const objKeyValue = {}
  const urlObj = window.location.href.split('?')[1]?.split('&')
  for (let i = 0; i < urlObj?.length; i++) {
    objKeyValue[urlObj[i]?.split('=')[0]] = urlObj[i].split('=')[1]
  }
  return objKeyValue
}

/**
 * 通过url匹配当前页面的pageName
 * @param pathPrefix string 类型枚举
 * @returns string 当前页面的pageName,用于美图埋点pageName
 */
export function handleLocationToPageName(pathPrefix) {
  const { pathname: path } = location
  // console.warn('搜索页')
  let href = 'SearchPage'
  if (path.includes(`/${pathPrefix}/search`)) {
    // console.warn('搜索结果页')
    href = 'SearchResult1'
  } else if (path.includes(`/${pathPrefix}/show`)) {
    // console.warn('搜索结果详情页')
    href = 'SearchResult2'
  }
  return href
}

/**
 * 轮询调用美图sdk的查询init方法，确定init之后执行回调函数
 * @param fn function 回调函数
 * @returns interval 当前页面的pageName,用于美图埋点pageName
 */
export function handlePollingMtstatIsInitialized(fn) {
  let interval = null

  const checkInitialization = () => {
    if (canUseDOM() && window.mtstat && window.mtstat.isInitialized()) {
      fn()
      clearInterval(interval)
    }
  }

  interval = setInterval(checkInitialization, 500)

  return interval
}

export function isDev() {
  return process.env.ENV === 'development' || !process.env.ENV
}
